import React from 'react';
import {callApi} from '../../../utils/callApi';
import {API_ROUTE_PATH} from '../../../utils/api_routes';
import {TWorkspace} from '../../../store/workspaces/types';
import {ContentState, convertFromHTML, EditorState} from 'draft-js';
import {COLORS} from '../../../utils/colors';
import {stateToHTML} from 'draft-js-export-html';
import {useDispatch, useSelector} from 'react-redux';
import {GetAllMessageTemplates} from '../../../store/messageTemplates/actions';
import {AppStore} from '../../../store/applicationState';
import {SelectItemType} from '../../ui/selects/DefaultSelect';
import {getIds} from '../../Analytics/utils/getIds';
import {EditPackage} from '../../../store/packages/actions';
import {deepClone} from '../../../utils/deepClone';
import {
  EMessageStatusType,
  ETypeOfLinkBeingSent,
  TDelayedMessage,
  TMessageStatus,
  TPackage,
  TSendNowResponse,
} from '../../../store/packages/types';
import {idsToDelayedGroups} from '../../../utils/idsToDelayedGroups';
import moment from 'moment';
import 'moment-timezone';

export const useSendPack = (
  ws: TWorkspace,
  packId: string,
  message: TDelayedMessage,
  link: string,
  closeModal: () => void
) => {
  const { MessageTemplates, Packages } = useSelector(
    (store: AppStore) => store
  );

  const [val, setVal] = React.useState(EditorState.createEmpty());

  const [subject, setSubject] = React.useState('');

  const [signature, setSignature] = React.useState(EditorState.createEmpty());

  const [errors, setErrors] = React.useState('');

  const [isSend, setIsSend] = React.useState(false);

  const [typeOfLinkBeingSent, setTypeOfLinkBeingSent] = React.useState(ETypeOfLinkBeingSent.publicLink);

  const [sendLoading, setSendLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const setEditors = () => {
      if (!message) return null;
      const html = message.email_message;
      const contentBlock = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const convertedHtmlVal = EditorState.createWithContent(contentState);

      const htmlSignature = message.email_signature;
      const contentSignatureBlock = convertFromHTML(htmlSignature);
      const contentSignatureState = ContentState.createFromBlockArray(
        contentSignatureBlock.contentBlocks
      );
      const convertedhtmlSignatureHtmlVal = EditorState.createWithContent(
        contentSignatureState
      );

      const newSubject = message.metadata.email_subject;

      setVal(convertedHtmlVal);
      setSubject(newSubject);
      setTypeOfLinkBeingSent(message.type_of_link_being_sent)
      setSignature(convertedhtmlSignatureHtmlVal);
    };
    if (!message) {
      dispatch(GetAllMessageTemplates.request({ workspace_name: ws.name }));
    } else {
      setEditors();
    }
  }, [dispatch, ws, message]);

  React.useEffect(() => {
    if (message) return null;
    const setEditors = () => {
      if (!MessageTemplates.current) return null;
      const html = MessageTemplates.current.email_content;
      const contentBlock = convertFromHTML(html);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const convertedHtmlVal = EditorState.createWithContent(contentState);

      const newSubject = MessageTemplates.current.email_subject;

      setVal(convertedHtmlVal);
      setSubject(newSubject);
      setTypeOfLinkBeingSent(MessageTemplates.current.type_of_link_being_sent)
    };

    if (MessageTemplates.current) {
      setEditors();
    } else {
      setTypeOfLinkBeingSent(ETypeOfLinkBeingSent.publicLink)
      setVal(EditorState.createEmpty());
      setSubject('');
      setSignature(EditorState.createEmpty());
    }
  }, [MessageTemplates, message]);

  // React.useEffect(() => {
  //   console.log('signature set:' + signature.getCurrentContent());
  // }, [signature]);

  const sendPackHandler = async (activeGroupIds: SelectItemType[]) => {
    if (!subject) return setErrors('Field subject are required');
    if (!val) return setErrors('Field message are required');
    if (!link) return setErrors('Field link are required');

    const content = stateToHTML(val.getCurrentContent());
    const signatureContent = stateToHTML(signature.getCurrentContent());
    try {
      setSendLoading(true);
      const data = {
        groups: getIds(activeGroupIds),
        subject,
        html_content: `
          ${content}
          <br />
          <a href="${link}" target="_blank" style="color: ${COLORS.accent}; text-decoration: underline" rel="nofollow">Go to Assessments</a>
          <br />
         ${signatureContent}
        `,
        email_message: content,
        type_of_link_being_sent: typeOfLinkBeingSent,
        email_signature: signatureContent,
      };

      const resp = (await callApi({
        path: API_ROUTE_PATH.packages.send(packId),
        method: 'post',
        data,
      })) as TSendNowResponse;

      if (!resp.emails_sent_number) {
        return setErrors(
          'All participants have responded to assessment package in current cycle\n'
        );
      }

      if (
        resp.requests_errors &&
        Array.isArray(resp.requests_errors) &&
        resp.requests_errors[0]
      ) {
        return setErrors((resp.requests_errors as string[]).join(', '));
      }

      const newPaks = deepClone(Packages.data) as TPackage[];
      if (newPaks.find((pack) => pack.id === packId).delayed_message) {
        newPaks
          .find((pack) => pack.id === packId)
          .delayed_message.push(resp.delayed_message);
      } else {
        newPaks.find((pack) => pack.id === packId).delayed_message = [
          { ...resp.delayed_message },
        ];
      }

      // let has_delayed = false;
      // newPaks
      //   .find((pack) => pack.id === packId)
      //   .delayed_message.forEach((del_message) => {
      //     if (!has_delayed)
      //       if (del_message.status === EMessageStatusType.registered)
      //         has_delayed = true;
      //   });
      // newPaks.find((pack) => pack.id === packId).is_has_delayed_message =
      //   has_delayed;

      dispatch(EditPackage.success(newPaks));

      // setTimeout(() => {
      //   closeModal();
      // }, 5000);
    } catch (e) {
      console.log(e);
      setErrors(e);
      setTimeout(() => {
        setErrors('');
        setIsSend(false);
      }, 10000);
    } finally {
      setIsSend(true);
      setSendLoading(false);
    }
  };

  const schedulePackHandler = async (
    activeGroupIds: SelectItemType[],
    sendAt: Date,
    trigger_time: string,
    status: TMessageStatus,
    trigger_days_after: number,
  ) => {
    if (!subject) return setErrors('Field subject are required');
    if (!val) return setErrors('Field message are required');
    if (!link) return setErrors('Field link are required');
    const content = stateToHTML(val.getCurrentContent());
    const signatureContent = stateToHTML(signature.getCurrentContent());


    const dummyZone = moment().tz('America/New_York');

    // -240 was changed to dummyZone for compatability with EST/EDT
    // console.log('-240:');
    // console.log(moment(sendAt).utcOffset(-240, true).utc().format());
    // console.log('dummyZone:');
    // console.log(
    //   moment(sendAt).utcOffset(dummyZone.utcOffset(), true).utc().format()
    // );
    try {
      setSendLoading(true);
      const data = {
        groups: getIds(activeGroupIds),
        trigger_days_after: trigger_days_after,
        type_of_link_being_sent: typeOfLinkBeingSent,
        send_at: status === EMessageStatusType.triggered ? trigger_time : sendAt
          ? moment(sendAt).utcOffset(dummyZone.utcOffset(), true).utc().format()
          : '0001-01-01T00:00:00Z',
        subject,
        html_content: `
            ${content}
            <br />
            <a href="${link}" target="_blank" style="color: ${COLORS.accent}; text-decoration: underline" rel="nofollow">Go to Assessments</a>
            <br />
           ${signatureContent}
          `,
        email_message: content,
        email_signature: signatureContent,
      };

      const resp = await callApi({
        path: API_ROUTE_PATH.packages.delayedReg(packId,status),
        method: 'post',
        data,
      });

      const newPaks = deepClone(Packages.data) as TPackage[];
      if (newPaks.find((pack) => pack.id === packId).delayed_message) {
        newPaks.find((pack) => pack.id === packId).delayed_message.push(resp);
      } else {
        newPaks.find((pack) => pack.id === packId).delayed_message = [
          { ...resp },
        ];
      }

      let has_delayed = false;
      let biggestDate: string = '';
      newPaks
        .find((pack) => pack.id === packId)
        .delayed_message.forEach((del_message) => {
          if (!has_delayed) {
            if (del_message.status === EMessageStatusType.registered ||
                del_message.status === EMessageStatusType.triggered)
              has_delayed = true;
          }
          if (
            del_message.status === EMessageStatusType.registered &&
            del_message.send_at &&
            del_message.send_at !== '0001-01-01T00:00:00Z'
          ) {
            if (
              biggestDate &&
              new Date(biggestDate) < new Date(del_message.send_at)
            ) {
              biggestDate = del_message.send_at;
            } else if (!biggestDate) {
              biggestDate = del_message.send_at;
            }
          }
        });

      newPaks.find((pack) => pack.id === packId).is_has_delayed_message =
        has_delayed;

      if (biggestDate)
        newPaks.find((pack) => pack.id === packId).min_number_of_days =
          moment(biggestDate).diff(moment(), 'days') + 1;
      dispatch(EditPackage.success(newPaks));
    } catch (e) {
      console.log(e);
      setErrors(e);
      setTimeout(() => {
        setErrors('');
        setIsSend(false);
      }, 10000);
    } finally {

      setIsSend(true);
      setSendLoading(false);
    }
  };

  const updateScheduledHandler = async (
    activeGroupIds: SelectItemType[],
    sendAt: Date,
    trigger_time: string,
    delayedMessage: TDelayedMessage,
    messgeStatus: TMessageStatus,
    trigger_days_after: number,
    callBack?: () => void
  ) => {
    if (!subject) return setErrors('Field subject are required');
    if (!val) return setErrors('Field message are required');
    if (!link) return setErrors('Field link are required');
    if (!typeOfLinkBeingSent) return setErrors('Field link of type are required');
    const content = stateToHTML(val.getCurrentContent());
    const signatureContent = stateToHTML(signature.getCurrentContent());
    const dummyZone = moment().tz('America/New_York');
    try {
      const data = {
        groups: getIds(activeGroupIds),
        trigger_days_after: trigger_days_after,
        send_at: messgeStatus === EMessageStatusType.triggered ? trigger_time : sendAt
            ? moment(sendAt).utcOffset(dummyZone.utcOffset(), true).utc().format()
            : '0001-01-01T00:00:00Z',
        subject,
        html_content: `
            ${content}
            <br />
            <a href="${link}" target="_blank" style="color: ${COLORS.accent}; text-decoration: underline" rel="nofollow">Go to Assessments</a>
            <br />
           ${signatureContent}
          `,
        email_message: content,
        type_of_link_being_sent: typeOfLinkBeingSent,
        email_signature: signatureContent,
      };

      const resp = await callApi({
        path: API_ROUTE_PATH.packages.delayedUpdate(
          packId,
          delayedMessage.id,
          messgeStatus,
          trigger_days_after,
        ),
        method: 'put',
        data,
      });

      const newPaks = deepClone(Packages.data) as TPackage[];
      const packIndex = newPaks.findIndex((pack) => pack.id === packId);
      const messageIndex = newPaks
        .find((pack) => pack.id === packId)
        .delayed_message.findIndex((msg) => msg.id === delayedMessage.id);
      newPaks[packIndex].delayed_message[messageIndex] = {
        ...delayedMessage,
        metadata: {
          email_subject: data.subject,
          email_content: data.html_content,
        },
        email_message: data.email_message,
        email_signature: data.email_signature,
        send_at: resp.send_at,
        status: messgeStatus,
        trigger_days_after: data.trigger_days_after,
        type_of_link_being_sent: data.type_of_link_being_sent,
        delayed_messages_to_groups: idsToDelayedGroups(data.groups as string[]),
      };

      let has_delayed = false;
      let biggestDate: string = '';
      newPaks
        .find((pack) => pack.id === packId)
        .delayed_message.forEach((del_message) => {
          if (!has_delayed) {
            if (del_message.status === EMessageStatusType.registered ||
                del_message.status === EMessageStatusType.triggered)
              has_delayed = true;
          }
          if (
            del_message.status === EMessageStatusType.registered &&
            del_message.send_at &&
            del_message.send_at !== '0001-01-01T00:00:00Z'
          ) {
            if (
              biggestDate &&
              new Date(biggestDate) < new Date(del_message.send_at)
            ) {
              biggestDate = del_message.send_at;
            } else if (!biggestDate) {
              biggestDate = del_message.send_at;
            }
          }
        });

      newPaks.find((pack) => pack.id === packId).is_has_delayed_message =
        has_delayed;

      if (biggestDate)
        newPaks.find((pack) => pack.id === packId).min_number_of_days =
          moment(biggestDate).diff(moment(), 'days') + 1;

      dispatch(EditPackage.success(newPaks));
    } catch (e) {
      console.log(e);
      setErrors(e);
      setTimeout(() => {
        setErrors('');
        setIsSend(false);
      }, 10000);
    } finally {
      if (callBack && !errors) callBack();
      setIsSend(true);
    }
  };

  const draftPackHandler = async (
    activeGroupIds: SelectItemType[],
    sendAt: Date
  ) => {
    if (!subject) return setErrors('Field subject are required');
    if (!val) return setErrors('Field message are required');
    if (!link) return setErrors('Field link are required');
    const content = stateToHTML(val.getCurrentContent());
    const signatureContent = stateToHTML(signature.getCurrentContent());
    const dummyZone = moment().tz('America/New_York');
    try {
      setSendLoading(true);
      const data = {
        groups: getIds(activeGroupIds),
        send_at: sendAt
          ? moment(sendAt).utcOffset(dummyZone.utcOffset(), true).utc().format()
          : '0001-01-01T00:00:00Z',
        subject,
        html_content: `
            ${content}
            <br />
            <a href="${link}" target="_blank" style="color: ${COLORS.accent}; text-decoration: underline" rel="nofollow">Go to Assessments</a>
            <br />
           ${signatureContent}
          `,
        email_message: content,
        type_of_link_being_sent: typeOfLinkBeingSent,
        email_signature: signatureContent,
      };
      const resp = await callApi({
        path: API_ROUTE_PATH.packages.delayedDraftReg(packId),
        method: 'post',
        data,
      });

      const newPaks = deepClone(Packages.data) as TPackage[];
      if (newPaks.find((pack) => pack.id === packId).delayed_message) {
        newPaks.find((pack) => pack.id === packId).delayed_message.push(resp);
      } else {
        newPaks.find((pack) => pack.id === packId).delayed_message = [
          { ...resp },
        ];
      }

      dispatch(EditPackage.success(newPaks));
    } catch (e) {
      console.log(e);
      setErrors(e);
      setTimeout(() => {
        setErrors('');
        setIsSend(false);
      }, 10000);
    } finally {
      setIsSend(true);
      setSendLoading(false);
    }
  };

  return {
    typeOfLinkBeingSent,
    setTypeOfLinkBeingSent,
    val,
    setVal,
    subject,
    setSubject,
    signature,
    setSignature,
    sendPackHandler,
    schedulePackHandler,
    draftPackHandler,
    updateScheduledHandler,
    errors,
    isSend,
    sendLoading,
    setErrors,
  };
};
