import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConvertSelectAss } from '../../hooks/useConvertSelectAss';
import {
  GetCharts,
  SetChartsFilters,
  TypeGetChartsR,
} from '../../store/analytics/actions';
import { AppStore } from '../../store/applicationState';
import { GetAllAssessmentsByTeam } from '../../store/assessments/actions';
import {
  ALL_ASS,
  ALL_GROUPS,
  ALL_PARTICIPANTS,
  CULTURE_PAGE_ID,
} from '../../utils/consts';
import { convertToDoubleStr } from '../../utils/convertToDoubleStr';
import { deepClone } from '../../utils/deepClone';
import { SelectItemType } from '../ui/selects/DefaultSelect';
import { TRange } from '../ui/selects/SelectWithRange';
import { getIds } from './utils/getIds';
import { useGroups } from '../../hooks/useGroups';
import {ParticipantSources, TGroupSelect} from "../../store/groups/types";

interface HashTable {
  [key: string]: TGroupSelect[];
}

export interface HashTableActiveGroup {
  [key: string]: IsActiveObject[];
}

export interface IsActiveObject {
  id: string;
  title: string;
  is_active: boolean
}

interface TRenderData {
  params: {
    is_all_assessments?: TypeGetChartsR['data']['params']['is_all_assessments'];
    is_all_participants?: TypeGetChartsR['data']['params']['is_all_participants'];
    period_of_answer_date: {
      is_all_time?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_all_time'];
      is_last_month?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_month'];
      is_last_quarter?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_quarter'];
      is_last_year?: TypeGetChartsR['data']['params']['period_of_answer_date']['is_last_year'];
      start_date?: TypeGetChartsR['data']['params']['period_of_answer_date']['start_date'];
      end_date?: TypeGetChartsR['data']['params']['period_of_answer_date']['end_date'];
    };
    assessments_ids?: TypeGetChartsR['data']['params']['assessments_ids'];
    groups_ids?: TypeGetChartsR['data']['params']['groups_ids'];
  };
}

export const useCharts = () => {
  const { Groups, Workspaces, Analytics } = useSelector(
    (store: AppStore) => store
  );
  const dispatch = useDispatch();
  const [activePeriod, setActivePeriod] = React.useState<TRange>('all_time');
  const [rangeDates, setRangeDates] = React.useState({
    start_date: '',
    end_date: '',
  });
  // const [selectDataGroups, setSelectDataGroups] = React.useState<
  //   SelectItemType[]
  // >([]);
  const [selectDataOriginSource, setSelectDataOriginSource] = React.useState<
      SelectItemType[]
  >([]);
  const [activeOriginSource, setActiveOriginSource] = React.useState<IsActiveObject[]>([])
  const [activeGroups, setActiveGroups] = React.useState<HashTableActiveGroup>({})
  const { selectDataAss, activeAssIds, setActiveAssIds } =
    useConvertSelectAss();
  const [customGraph, setCustomGraph] = React.useState(false);
  const [activeGroupIds, setActiveGroupIds] = React.useState<SelectItemType[]>(
    []
  );
  useGroups();

  const getCharts = React.useCallback(
    (
      {
        params: {
          is_all_assessments = true,
          is_all_participants = true,
          period_of_answer_date: {
            is_all_time = true,
            is_last_month = false,
            is_last_quarter = false,
            is_last_year = false,
            start_date = '',
            end_date = '',
          },
          assessments_ids = [],
          groups_ids = [],
        },
      }: TRenderData,
      isNotSend?: boolean,
      new_opened_visualizations?: boolean
    ) => {
      if (!Workspaces.current || !Workspaces.current.id) return null;

      const data = {
        opened_visualizations: new_opened_visualizations
          ? []
          : Analytics.filters.opened_visualizations,
        params: {
          is_all_assessments,
          is_all_participants,
          period_of_answer_date: {
            is_all_time,
            is_last_month,
            is_last_quarter,
            is_last_year,
            start_date,
            end_date,
          },
          assessments_ids,
          groups_ids,
        },
      };
      dispatch(SetChartsFilters(data));

      if (!isNotSend) {
        dispatch(
          GetCharts.request({
            data: {
              workspace_id: Workspaces.current.id,
              page_id: CULTURE_PAGE_ID,
              ...data,
            },
          })
        );
      }
    },
    [Workspaces, Analytics.filters.opened_visualizations, dispatch]
  );

  React.useEffect(() => {
    if (Workspaces.current && Workspaces.current.id) {
      // dispatch(getGroupsR(Workspaces.current.id));
      dispatch(
        GetAllAssessmentsByTeam.request({
          workspaceId: Workspaces.current.id,
        })
      );
      setActiveGroupIds([ALL_PARTICIPANTS]);
      getCharts(
        {
          params: { period_of_answer_date: {} },
        },
        false,
        true
      );
    }
    // eslint-disable-next-line
  }, [Workspaces, dispatch]);

  React.useEffect(() => {
    const setDataOriginSource = () => {
      if (!Groups.data) return null;
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      const newActiveGroups: HashTableActiveGroup = {}
      const newActiveOriginSource: IsActiveObject[]  = [{
        id: ALL_PARTICIPANTS.id,
        title: ALL_PARTICIPANTS.title,
        is_active: true
      }];

      if (Groups.data && Groups.data[1]) {
        newSelects.push(ALL_GROUPS);
        newActiveOriginSource.push({
          id: ALL_GROUPS.id,
          title: ALL_GROUPS.title,
          is_active: false
        })
      }

      const hashTable: HashTable = {};

      const arrOfKeys: string[] = []

      Groups.data.forEach((itm) => {
        const os = itm.origin_source !== '' ? itm.origin_source : 'default'

        if (!hashTable[os]) {
          arrOfKeys.push(os)
          hashTable[os] = []
          newActiveGroups[os] = []

          newActiveGroups[os].push({
            id: ALL_GROUPS.id,
            title: ALL_GROUPS.title,
            is_active: false
          })
        }

        // hashTable[os].push({
        //   id: itm.id,
        //   origin_source: os,
        //   name: itm.name
        // })

        newActiveGroups[os].push({
          id:itm.id,
          title: itm.name,
          is_active: false
        })
      });

      arrOfKeys.sort()

      arrOfKeys.forEach((key, idx) => {
       if (key !== 'default') {
         newSelects.push({
           id: key,
           value: key,
           title: ParticipantSources[key],
           groups: hashTable[key],
         })

         newActiveOriginSource.push({
           id: key,
           title: ParticipantSources[key],
           is_active: false
         });
       }

        if (idx === arrOfKeys.length-1) {
          newSelects.push({
            id: 'default',
            value: 'default',
            title: ParticipantSources[''],
            groups: hashTable['default'],
          })

          newActiveOriginSource.push({
            id: 'default',
            title: ParticipantSources[''],
            is_active: false
          });
        }
      })

      setActiveGroups(newActiveGroups)
      setActiveOriginSource(newActiveOriginSource)
      setSelectDataOriginSource(newSelects);
    };

    if (Groups.data && Groups.data[0] && !Groups.loading) {
      setDataOriginSource();
    } else if (!Groups.data || !Groups.data[0]) {
      const newSelects: SelectItemType[] = [ALL_PARTICIPANTS];
      setSelectDataOriginSource(newSelects);
    }
  }, [Groups.data, Groups.loading]);

  const setResetGroups = (dataGroup:  HashTableActiveGroup, key: string, value: boolean) => {
    if (dataGroup.hasOwnProperty(key)) {
      dataGroup[key]?.length > 0 && dataGroup[key].forEach((obj, idx) => {
        if (idx === 0 || key === 'default') {
          dataGroup[key][idx].is_active = value
        } else {
          dataGroup[key][idx].is_active = false
        }

      })
    }
  }

  const setAllValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], value: boolean) => {
    dataOriginSource?.length > 0 && dataOriginSource.forEach((itm, idx) => {
      if (itm.id !== ALL_PARTICIPANTS.id) {
        dataOriginSource[idx].is_active = value
        setResetGroups(dataGroup, itm.id, value)
      } else {
        dataOriginSource[idx].is_active = !value
      }
    })
  }

  const setOnceValueOriginSource = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string) => {
    const idx = dataOriginSource.findIndex((it) => it.id === originSource)
    if (idx >= 0) {
      dataOriginSource[idx].is_active = !dataOriginSource[idx].is_active
      setResetGroups(dataGroup, dataOriginSource[idx].id, dataOriginSource[idx].is_active)
      dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
      dataOriginSource[1].is_active = checkAllGroups(dataGroup)
    }
  }

  const setAllValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, value: boolean) => {
    dataGroup[originSource].forEach((itm, idx) => {
      if (idx === 0) {
        dataGroup[originSource][idx].is_active = value
      } else {
        dataGroup[originSource][idx].is_active = !value
      }
    })

    dataOriginSource[1].is_active = checkAllGroups(dataGroup)
  }

  const setOnceValueGroup = (dataGroup:  HashTableActiveGroup, dataOriginSource: IsActiveObject[], originSource: string, groupId: string) => {
    if (dataGroup[originSource]?.length < 3 && originSource !== 'default')
      return

    if (dataGroup[originSource][0].is_active) {
      dataGroup[originSource][0].is_active = false
    }

    const idx = dataGroup[originSource].findIndex((it) => it.id === groupId)
    if (idx >= 0) {
      dataGroup[originSource][idx].is_active = !dataGroup[originSource][idx].is_active

      if (!dataGroup[originSource][idx].is_active) {
        dataGroup[originSource][0].is_active = dataGroup[originSource][idx].is_active
      }


      const idxForAllGroupsFalse = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === false )

      if (idxForAllGroupsFalse === -1 && originSource !== 'default') {
        dataGroup[originSource].forEach((itm, i) => {
          if (i === 0) {
            dataGroup[originSource][i].is_active = true
          } else {
            dataGroup[originSource][i].is_active = false
          }
        })
      }

      const idxForAllGroupsTrue = dataGroup[originSource].findIndex((it, i) => i !== 0 && it.is_active === true && originSource !== 'default')

      if (idxForAllGroupsTrue === -1 && originSource !== 'default') {
        dataGroup[originSource].forEach((itm, i) => {
          if (i === 0) {
            dataGroup[originSource][i].is_active = true
          } else {
            dataGroup[originSource][i].is_active = false
          }
        })
      }

      dataOriginSource[0].is_active = checkAllParticipants(dataGroup)
      dataOriginSource[1].is_active = checkAllGroups(dataGroup)
    }
  }

  const checkAllParticipants = (dataGroup:  HashTableActiveGroup): boolean => {
    for (const key in dataGroup) {
      if (dataGroup.hasOwnProperty(key)) {
        const idx = dataGroup[key].findIndex((it) => it.is_active === true)
        if (idx >= 0)
          return !dataGroup[key][idx].is_active
      }
    }
    return true
  }

  const checkAllGroups = (dataGroup:  HashTableActiveGroup): boolean => {
    for (const key in dataGroup) {
      if (dataGroup.hasOwnProperty(key)) {
        if (!dataGroup[key][0].is_active)
          return dataGroup[key][0].is_active
      }
    }
    return true
  }




  const getPeriodData = () => {
    return {
      is_all_time: activePeriod === 'all_time',
      is_last_month: activePeriod === 'last_month',
      is_last_quarter: activePeriod === 'last_quarter',
      is_last_year: activePeriod === 'last_year',
      start_date: rangeDates.start_date,
      end_date: rangeDates.end_date,
    };
  };

  const assSelectHandler = (data: SelectItemType) => {
    if (
      data.value === ALL_ASS.value &&
      activeAssIds[0].value === ALL_ASS.value
    ) {
      return null;
    }
    const newData = deepClone(activeAssIds) as SelectItemType[];
    const ass_ids: string[] = [];
    const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();

    if (data.value === ALL_ASS.value) {
      setActiveAssIds([ALL_ASS]);
      return getCharts({
        params: {
          period_of_answer_date: { ...sendPeriodData },
          //period_of_hiring_date: {},
          is_all_assessments: true,
          assessments_ids: [],
          is_all_participants: !groups_ids[0],
          groups_ids,
        },
      });
    }

    let hasVal = false;

    if (newData[0] && newData[0].value === ALL_ASS.value) {
      newData.splice(0, 1);
    }

    newData.forEach((itm, i) => {
      if (itm.value === data.value) {
        newData.splice(i, 1);
        hasVal = true;
      }
    });

    if (!hasVal) {
      newData.push(data);
    }

    newData.forEach((itm) => {
      if (itm.value !== ALL_ASS.value) {
        ass_ids.push(itm.value);
      }
    });

    if (!newData[0]) {
      newData.push(ALL_ASS);
    }

    setActiveAssIds(newData);
    return getCharts({
      params: {
        period_of_answer_date: { ...sendPeriodData },
        //period_of_hiring_date: {},
        is_all_participants: !groups_ids[0],
        groups_ids,
        is_all_assessments: !ass_ids[0],
        assessments_ids: ass_ids,
      },
    });
  };

  const getGroupIds = (groups: HashTableActiveGroup, isAllParticipants: boolean): string[] => {
    let groupsIdArr: string[] = []
    if (isAllParticipants) {
      return groupsIdArr
    }
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        if (groups[key][0].is_active) {
          groups[key].forEach((itm) => {
            itm.id !== ALL_GROUPS.id && groupsIdArr.push(itm.id)
          })
        } else {
          groups[key].forEach((itm) => {
            itm.id !== ALL_GROUPS.id && itm.is_active && groupsIdArr.push(itm.id)
          })
        }
      }
    }

    return groupsIdArr
  }

  const originSourceSelectHandler = (data: SelectItemType) => {
    if (
        data.id === ALL_PARTICIPANTS.id &&
        activeOriginSource[0].is_active
    ) {
      return null;
    }
    if (
        data.id === ALL_GROUPS.id &&
        activeOriginSource[1].is_active
    ) {
      return null;
    }

      const newActiveOriginSource = deepClone(activeOriginSource);
      const newActiveGroups = deepClone(activeGroups);

      switch (data.id) {
        case ALL_PARTICIPANTS.id:
          setAllValueOriginSource(newActiveGroups, newActiveOriginSource, false)
            setActiveOriginSource(newActiveOriginSource)
            setActiveGroups(newActiveGroups)
          break
        case ALL_GROUPS.id:
          setAllValueOriginSource(newActiveGroups, newActiveOriginSource, true)
            setActiveOriginSource(newActiveOriginSource)
            setActiveGroups(newActiveGroups)
          break
        default:
          setOnceValueOriginSource(newActiveGroups, newActiveOriginSource, data.id as string)
          setActiveOriginSource(newActiveOriginSource)
          setActiveGroups(newActiveGroups)
      }
  }

  const groupSelectHandler = (data: SelectItemType, parentId: string) => {
    if (
        data.id === ALL_GROUPS.id &&
        activeGroups[parentId][0].is_active
    ) {
      return null;
    }

    const newActiveOriginSource = deepClone(activeOriginSource);
    const newActiveGroups = deepClone(activeGroups);

    switch (data.id) {
      case ALL_GROUPS.id:
        setAllValueGroup(newActiveGroups, newActiveOriginSource, parentId, true)
        setActiveOriginSource(newActiveOriginSource)
        setActiveGroups(newActiveGroups)
        break
      default:
        setOnceValueGroup(newActiveGroups, newActiveOriginSource, parentId, data.id as string)
        setActiveOriginSource(newActiveOriginSource)
        setActiveGroups(newActiveGroups)
    }
  }

  const selectedRange = (): null | string => {
    if (activePeriod) {
      switch (activePeriod) {
        case 'all_time':
          return 'All time';
        case 'last_month':
          return 'Last month';
        case 'last_quarter':
          return 'Last quarter';
        case 'last_year':
          return 'Last year';
        default:
          return null;
      }
    }

    if (rangeDates.start_date) {
      const stDate = new Date(rangeDates.start_date);
      const enDate = new Date(rangeDates.end_date);
      const sy = stDate.getFullYear();
      const sm = stDate.getMonth() + 1;
      const sd = stDate.getDate();
      const ey = enDate.getFullYear();
      const em = enDate.getMonth() + 1;
      const ed = enDate.getDate();

      const stD = `${sy}.${convertToDoubleStr(`${sm}`)}.${convertToDoubleStr(
        `${sd}`
      )}`;
      const enD = `${ey}.${convertToDoubleStr(`${em}`)}.${convertToDoubleStr(
        `${ed}`
      )}`;
      return `${stD} - ${enD}`;
    }

    return null;
  };

  const applyHandler = () => {
    const ass_ids = getIds(activeAssIds, true) as string[];
    // const groups_ids = getIds(activeGroupIds) as string[];
    const sendPeriodData = getPeriodData();
    return getCharts({
      params: {
        is_all_participants: activeOriginSource[0].is_active,
        period_of_answer_date: { ...sendPeriodData },
        groups_ids: getGroupIds(activeGroups, activeOriginSource[0].is_active),
        is_all_assessments: !ass_ids[0],
        assessments_ids: ass_ids as string[],
      },
    });
  };

  return {
    groupSelectHandler,
    activeGroups,
    activeOriginSource,
    selectDataOriginSource,
    customGraph,
    activeGroupIds,
    setActivePeriod,
    setRangeDates,
    setCustomGraph,
    selectedRange,
    getCharts,
    originSourceSelectHandler,
    selectDataAss,
    activeAssIds,
    assSelectHandler,
    applyHandler,
  };
};
