import React from 'react';
import { debounce } from 'lodash';
import { useParams } from 'react-router-dom';
import { ELinkType } from '../../../types/respondingTypes';
import { API_ROUTE_PATH } from '../../../utils/api_routes';
import { callApi } from '../../../utils/callApi';
import { deepClone } from '../../../utils/deepClone';
import { getUsers } from '../api';
import { DEFAULT_STEPS_COUNT } from '../config';
import {
  TCollaborations,
  TRespAnswer,
  TRespIdentify,
  TRespUser,
} from '../types';
import { getPreferences, ICbData } from '../api/getPreferences';
import { SelectItemType } from '../../../components/ui/selects/DefaultSelect';
import { getNativeLangSelect } from '../../../components/Responding/assLang';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const useResponding = () => {
  const [step, setStep] = React.useState(1);
  const [user, setUser] = React.useState<TRespUser | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<TRespUser[] | null>(null);
  const [identifyData, setIdentifyData] = React.useState<TRespIdentify | null>(
    null
  );

  const [browserID, setBrowserID] = React.useState<string>('');
  const [fingerPrint, setFingerPrint] = React.useState<string>('');
  const [errorText, setErrorText] = React.useState('');
  const [currAss, setCurrAss] = React.useState<
    TRespIdentify['package']['assessments'][0] | null
  >(null);
  const { id } = useParams<{ id: string }>();
  const [answers, setAnswers] = React.useState<TRespAnswer[]>([]);
  const [pref, setPref] = React.useState<ICbData['data' | null]>(null);
  const [selectLang, setSelectLang] = React.useState<SelectItemType[]>([
    {
      title: 'English',
      value: 'en_US',
      id: 'en_US',
    },
  ]);

  React.useEffect(()=>{
   getFingerprint().then((fingerprint) => {
     setFingerPrint(fingerprint.visitorId)
   });
  }, [])
  React.useEffect(() => {
    if (step === 3 ){
      setAnswers([])
    }
  }, [step])

  React.useEffect(() => {
    (async () => {
      const respPref = await getPreferences({ id });
      if (respPref.success) {
        setSelectLang(getNativeLangSelect(respPref.data.languages_tags));
        setPref(respPref.data);
         if (respPref.data.personal_data) {
           const dataToSend = {
             link: respPref.data.personal_data.link,
             first_name: respPref.data.personal_data!.first_name,
             last_name: respPref.data.personal_data!.last_name,
             email:
                 respPref.data.personal_data.email !== '' ? respPref.data.personal_data.email.trim().toLowerCase() : '',
             phone:
                 respPref.data.personal_data.phone !== ''
                     ? (respPref.data.personal_data.phone_code.split(' ')[1] + respPref.data.personal_data.phone).trim()
                     : '',
             payroll_id:
                 respPref.data.personal_data.payroll_id  !== '' ? respPref.data.personal_data.payroll_id.trim() : ''
           };

           const respIdent = (await callApi({
             path: API_ROUTE_PATH.participants.identify,
             method: 'post',
             data: dataToSend,
           })) as TRespIdentify;
           if (!respIdent.package) throw Error('No package');

           if (!respIdent.participant) throw Error('No participant');

           setIdentifyData(respIdent)
           setStep(3)
           setLoading(false);
         } else {
           const resp = await getUsers({ id, linkType: ELinkType.package });
           if (resp.success) {
             setUsers(resp.users);
           }
           if (!resp.success && resp.errorText) {
             setErrorText(`${resp.errorText}`);
           }
           setLoading(false);
        }
      }
    })();
  }, [id]);

  async function getFingerprint() {

    const fp = await FingerprintJS.load();

    const result = await fp.get();
    return result
  }

  const sendAnswers = async (
    sendAnswers?: TRespAnswer[],
    sendCollab?: TCollaborations[],
  ): Promise<boolean> => {
    setLoading(true);



    try {
      await callApi({
        path: API_ROUTE_PATH.answers.path,
        method: 'post',
        data: {
          finger_print: fingerPrint,
          browser_id: browserID,
          link: id,
          link_created_at: identifyData?.package.link_created_at,
          participant_id: identifyData?.participant.id,
          package_id: identifyData?.package.id,
          assessment_id: currAss?.id,
          answers: sendAnswers,
          collaboration: sendCollab,
        },
      });
      return true;
    } catch (e) {
      setErrorText(`${e}`);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const answerHandler = debounce(async (answer: TRespAnswer, stepNum: number) => {
    if (!currAss) return null;
    if (!answer) return console.error('No answer');
    const newAnswers = deepClone(answers) as TRespAnswer[];
    const existAnswer = newAnswers.find(
      (an) => an.question_id === answer.question_id
    );

    if (existAnswer) {
      existAnswer.answer_body = answer.answer_body;
      existAnswer.answer_value = answer.answer_value;
    } else {
      newAnswers.push(answer);
    }

    setAnswers(newAnswers);
    if (stepNum > currAss.questions.length + DEFAULT_STEPS_COUNT) {
      const success = await sendAnswers(newAnswers);
      console.log(success, 'success');
      if (success) {
        return setStep(stepNum);
      }
      return null;
    }
    return setStep(stepNum);
  }, 100);

  const backToList = () => {
    if (!currAss) return console.error('Assessment does not exist');
    const newIdentifyData = deepClone(identifyData) as TRespIdentify;
    newIdentifyData.package.assessments =
      newIdentifyData.package.assessments.filter(
        (ass) => ass.id !== currAss.id
      );
    setIdentifyData(newIdentifyData);
    setAnswers([]);
    setStep(3);
  };

  return {
    setBrowserID,
    pref,
    step,
    setStep,
    user,
    setUser,
    users,
    identifyData,
    setIdentifyData,
    currAss,
    setCurrAss,
    answers,
    answerHandler,
    setAnswers,
    backToList,
    sendAnswers,
    errorText,
    loading,
    selectLang,
  };
};

