import {ETypeOfLinkBeingSent} from "../packages/types";

export interface TMessageTemplatesState {
  readonly loading: boolean;
  readonly data: TMessageTemplate[] | null;
  readonly current: TMessageTemplate | null;
  readonly errors?: string | undefined;
}

export enum EMessageTemplateType {
  custom_type = 'custom_type',
  organization_type = 'organization_type',
}

export type TMessageTemplateType =
  | EMessageTemplateType.custom_type
  | EMessageTemplateType.organization_type
  | string;

export interface TMessageTemplate {
  id: string;
  name: string;
  type: TMessageTemplateType;
  email_subject: string;
  email_content: string;
  type_of_link_being_sent: ETypeOfLinkBeingSent;
  created_at: string;
  updated_at: string;
}
enum ActionTypes {
  GET_ALL_TEMPLATES_R = '@@messageTemplates/GET_ALL_TEMPLATES_R',
  GET_ALL_TEMPLATES_S = '@@messageTemplates/GET_ALL_TEMPLATES_S',
  GET_ALL_TEMPLATES_E = '@@messageTemplates/GET_ALL_TEMPLATES_E',

  CREATE_TEMPLATE_R = '@@messageTemplates/CREATE_TEMPLATE_R',
  CREATE_TEMPLATE_S = '@@messageTemplates/CREATE_TEMPLATE_S',
  CREATE_TEMPLATE_E = '@@messageTemplates/CREATE_TEMPLATE_E',

  DELETE_TEMPLATE_R = '@@messageTemplates/DELETE_TEMPLATE_R',
  DELETE_TEMPLATE_S = '@@messageTemplates/DELETE_TEMPLATE_S',
  DELETE_TEMPLATE_E = '@@messageTemplates/DELETE_TEMPLATE_E',

  SET_TEMPLATE = '@@messageTemplates/SET_PACK',
}

export default ActionTypes;
